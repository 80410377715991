import { checkIsDev, checkIsProd } from "@/lib/env";

type ILoggerType = "error" | "warn" | "log";

interface ILoggerOptions {
  type?: ILoggerType;
  scope?: string;
  isOnlyDev?: boolean;
}

const defaultOptions: ILoggerOptions = {
  type: "log",
  isOnlyDev: true,
};

export const logger = (
  message: Record<string, unknown> | string,
  options: ILoggerOptions = defaultOptions
): void => {
  const mergedOptions = { ...defaultOptions, ...options };
  const { type, scope, isOnlyDev } = mergedOptions;
  if (isOnlyDev && !checkIsDev()) return;
  // do not log normal messages in production
  if (type === "log" && checkIsProd() && isOnlyDev) return;

  const prefixes = [];
  if (scope) prefixes.push(`[${scope}]`);
  // eslint-disable-next-line no-console,@typescript-eslint/no-non-null-assertion
  console[type!](
    `[${new Date().toISOString()}]${prefixes.join(
      ""
    )}[${type!.toUpperCase()}] - `,
    message
  );
};
