const api_url = 'kit-ru.voximplant.com'
// const api_url = 'kit-eu.voximplant.xyz'

export const softphoneConfig = {
  visualSettings: {
    drawMode: "inline",
    cssSelector: "#softphone",
  },
  // TODO: store last user data settings
  authSettings: {
    api_url,
    domain: '',
    email: '',
    type: "password",
  },
};

export const windowConfig = {
  width: 376,
  height: 524,
};

export const SOFTPHONE_BACK_URL =
  "https://kit-ru.voximplant.com/static/widgets/softphone/js/app.js";

export const SOFTPHONE_FRONT_URL = 'https://softphone.sergeygorshkov.ru';
