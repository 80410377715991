export enum BridgeCommand {
	Authed = "AUTHED",
	MakeCall = "MAKE_CALL",
	StatusUpdated = "STATUS_UPDATED",
	IncomingCall = "INCOMING_CALL",
	OutgoingCall = "OUTGOING_CALL",
	Ping = "PING",
	Pong = "PONG",
	DropWebsocket = "DROP_WEBSOCKET",
}
