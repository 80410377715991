export const loadScript = (src: string) => {
  return new Promise(function (resolve, reject) {
    let shouldAppend = false;
    const existingElement = document.querySelector('script[src="' + src + '"]');
    if (!existingElement) {
      const newElement = document.createElement("script");
      newElement.type = "text/javascript";
      newElement.async = true;
      newElement.defer = true;
      newElement.src = src;
      shouldAppend = true;

      newElement.addEventListener("error", reject);
      newElement.addEventListener("abort", reject);
      newElement.addEventListener("load", function loadScriptHandler() {
        newElement.setAttribute("data-loaded", "true");
        resolve(newElement);
      });

      if (shouldAppend) document.head.appendChild(newElement);
    } else if (existingElement.hasAttribute("data-loaded")) {
      resolve(existingElement);
      return;
    }
  });
};
