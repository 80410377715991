import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Softphone from "@/views/Softphone.vue";
import Offline from "@/views/Offline.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Softphone",
    component: Softphone,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Offline",
    component: Offline,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
