
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { loadScript } from "@/lib/scripts";
import Preloader from "@/components/UI/Preloader.vue";
import { logger } from "@/lib/logger";
import { SOFTPHONE_BACK_URL, softphoneConfig } from "@/config/softphone";
import { getUserInfo } from "@/service/softphone/user";
import { softphoneChannel } from '@/config/ipc';
import { BridgeCommand } from '@/enums/BridgeCommand';
import { BridgeMessage } from '@/entities/BridgeMessage';
import { KIT_API_URL } from '@/config/kitapi';

export default defineComponent({
  components: { Preloader },
  setup() {
    const kit = ref(window.VoximplantKit);
    const electron = ref(window.electron);
    const isSoftphoneLoading = ref(true);

    onMounted(async () => {
      try {
        await loadScript(SOFTPHONE_BACK_URL);
        kit.value = window.VoximplantKit;
        kit.value = window.VoximplantKit;
        kit.value.destroy();
        kit.value.init(softphoneConfig);

        kit.value.App.on("StatusUpdated", (event) => {
          electron.value.ipcRendererSend(softphoneChannel, {
            command: BridgeCommand.StatusUpdated,
            payload: {
              status: event.status,
            },
          });
        });

        kit.value.Call.on("IncomingCall", async (event) => {
          const { number } = event;
          electron.value.ipcRendererSend(softphoneChannel, {
            command: BridgeCommand.IncomingCall,
            payload: {
              number,
            },
          });
        });

        const dropWsConnection = () => {
          electron.value.ipcRendererSend(softphoneChannel, {
            command: BridgeCommand.DropWebsocket,
          });
        };

        kit.value.App.on("ConnectionClosed", () => {
          dropWsConnection();
        });

        kit.value.App.on("ConnectionFailed", () => {
          dropWsConnection();
        });

        kit.value.App.on("AuthCompleted", async () => {
          // TODO: get data from softphone
          const accessToken = localStorage.kit_access_token;
          const domain = localStorage.kit_domain as string;

          const userData = await getUserInfo({
            accessToken,
            domain,
            baseUrl: KIT_API_URL,
          });

          const userId = userData.result?.user?.id as string;

          electron.value.ipcRendererSend(softphoneChannel, {
            command: BridgeCommand.Authed,
            payload: {
              accessToken: localStorage.kit_access_token,
              domain,
              userId,
            },
          });

          electron.value.ipcRendererReceive(softphoneChannel, (message: BridgeMessage) => {
            switch (message.command) {
              case BridgeCommand.MakeCall: {
                kit.value.App.call(message.payload.number);
                break;
              }

              case BridgeCommand.Ping: {
                electron.value.ipcRendererSend(softphoneChannel, {
                  command: BridgeCommand.Pong,
                  payload: {
                    uuid: message.payload.uuid,
                  },
                });
                break;
              }
              default: {
                logger(`unknown command ${message.command}`, {
                  scope: "Softphone bridge",
                });
              }
            }
          });
        });
      } catch (e) {
        logger(e, { type: "error" });
      } finally {
        isSoftphoneLoading.value = false;
      }
    });

    onUnmounted(() => {
      kit.value.destroy();
    });

    return {
      isSoftphoneLoading,
    };
  },
});
