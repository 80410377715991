export const getUserInfo = async (params: {
  domain: string;
  accessToken: string;
  baseUrl: string;
}) => {
  const { baseUrl, accessToken, domain } = params;
  const url = `https://${baseUrl}/api/v2/account/getAccountInfo?domain=${domain}&access_token=${accessToken}`;
  const response = await fetch(url, {
    method: "POST",
  });
  const data = await response.json();
  return data;
};
